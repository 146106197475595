import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTachometerAlt,
  faClipboardCheck,
  faTasks,
  faBooks,
  faExchangeAlt,
  faCogs,
  faArrowAltFromTop,
  faCubes,
  faIndustryAlt,
  faNotEqual,
  faWarehouseAlt,
  faTruckContainer,
  faTruckLoading,
  faCheckCircle,
  faPalletAlt,
  faFileContract,
  faCircleNotch,
  faBoxes,
  faUsers,
  faFileExclamation,
  faFileCheck,
  faPen,
  faGripHorizontal,
  faGripLines,
  faSync,
  faTimes,
  faCheckDouble,
  faPlusSquare,
  faDraftingCompass,
  faFileCsv,
  faFilter,
  faFileExcel,
  faCloudDownload,
  faAngleRight,
  faAngleLeft,
  faTrashAlt,
  faArrowLeft,
  faBell,
  faChartLineDown,
  faArrowUp,
  faPalette,
  faCheck,
  faSlash,
  faQuestion,
  faArrowDown,
  faTrashRestore,
  faEye,
  faBox,
  faSearch,
  faCartPlus,
  faArrowCircleDown,
  faMinusSquare,
  faMinus,
  faInboxIn,
  faHeart as fasHeart,
  faBadge,
  faArrowsRotate,
  faCartXmark,
  faReply,
  faCalendarDay,
  faCalendarDays,
  faLocationDot,
  faGauge,
  faGaugeLow,
  faGaugeMin,
  faHand,
  faGear,
  faPlusLarge,
} from '@fortawesome/pro-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons'
import { formatISODuration } from 'date-fns/fp'

// Import and put icons here
library.add(
  faTachometerAlt,
  faClipboardCheck,
  faTasks,
  faBooks,
  faExchangeAlt,
  faCogs,
  faArrowAltFromTop,
  faCubes,
  faIndustryAlt,
  faNotEqual,
  faWarehouseAlt,
  faTruckContainer,
  faTruckLoading,
  faCheckCircle,
  faPalletAlt,
  faFileContract,
  faCircleNotch,
  faBoxes,
  faUsers,
  faFileExclamation,
  faFileCheck,
  faPen,
  faGripHorizontal,
  faGripLines,
  faSync,
  faTimes,
  faCheckDouble,
  faPlusSquare,
  faDraftingCompass,
  fasHeart,
  farHeart,
  faFileCsv,
  faFilter,
  faFileExcel,
  faCloudDownload,
  faAngleLeft,
  faAngleRight,
  faTrashAlt,
  faArrowLeft,
  faChartLineDown,
  faBell,
  faArrowDown,
  faArrowUp,
  faQuestion,
  faEye,
  faTrashRestore,
  faPalette,
  faCheck,
  faSlash,
  faBadge,
  faBox,
  faSearch,
  faCartPlus,
  faArrowCircleDown,
  faMinusSquare,
  faMinus,
  faInboxIn,
  faArrowsRotate,
  faCartXmark,
  faReply,
  faCalendarDay,
  faCalendarDays,
  faLocationDot,
  faGauge,
  faGaugeLow,
  faGaugeMin,
  faHand,
  faGear,
  faPlusLarge
)
